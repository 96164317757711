/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2024-11-25 11:55:20
 */

// import { api } from './service.js'
import { service } from './service.js'

const server = {
	eget(req) {
		return service.get('/express/eget', req)
	},
	epost(req) {
		return service.post('/express/epost', req)
	},

	getFUNC(req) {
		return service.post(`${process.env.VUE_APP_BASE_API}/tc/p/list/photos`, req)
	},
	postFUC(req) {
		return service.get(`${process.env.VUE_APP_BASE_API}/tc/p/photo`, req)
	},
}

function download(apk) {
	let eleLink = document.createElement('a')
	eleLink.setAttribute('href', apk)
	eleLink.style.display = 'none'
	document.body.appendChild(eleLink)
	eleLink.click()
	document.body.removeChild(eleLink)
}

function GetQueryString(str) {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		let reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
		let r = window.location.search.substr(1).match(reg)
		if (r != null) return unescape(r[2])
	}

	return null
}

function timeAgo(dateString) {
	const now = new Date()
	const past = new Date(dateString)
	const diff = now - past // 毫秒差值

	const seconds = Math.floor(diff / 1000)
	const minutes = Math.floor(seconds / 60)
	const hours = Math.floor(minutes / 60)
	const days = Math.floor(hours / 24)
	const weeks = Math.floor(days / 7)
	const months = Math.floor(days / 30)
	const years = Math.floor(days / 365)

	if (years > 0) {
		return years + ' years ago'
	} else if (months > 0) {
		return months + ' months ago'
	} else if (weeks > 0) {
		return weeks + ' weeks ago'
	} else if (days > 0) {
		return days + ' days ago'
	} else if (hours > 0) {
		return hours + ' hours ago'
	} else if (minutes > 0) {
		return minutes + ' minutes ago'
	} else {
		return seconds + ' seconds ago'
	}
}

function dateStringToTimestamp(dateString) {
	const date = new Date(dateString)
	return date.getTime()
}

function formatDate(oldDate, fmt) {
	// console.log(this.$global.formatDate(Date.now(), 'yyyy-MM-dd hh:mm'))
	// console.log(this.$global.formatDate(Date.now(), 'MMM dd, yyyy'))
	let date
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else if (oldDate instanceof Date) {
		date = oldDate
	} else {
		date = new Date()
	}

	const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

	const o = {
		yyyy: date.getFullYear(),
		yy: String(date.getFullYear()).slice(-2),
		MMM: monthsShort[date.getMonth()],
		MM: ('0' + (date.getMonth() + 1)).slice(-2),
		M: date.getMonth() + 1,
		dd: ('0' + date.getDate()).slice(-2),
		d: date.getDate(),
		hh: ('0' + date.getHours()).slice(-2),
		h: date.getHours(),
		mm: ('0' + date.getMinutes()).slice(-2),
		m: date.getMinutes(),
		ss: ('0' + date.getSeconds()).slice(-2),
		s: date.getSeconds(),
	}

	return fmt.replace(/(yyyy|yy|MMM|MM|M|dd|d|hh|h|mm|m|ss|s)/g, (match) => {
		return o[match]
	})
}

// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatDate,
	server,
	download,
	dateStringToTimestamp,
	timeAgo,
}
